export const PubSub = {
   list: new Map(),
   on(eventType:string, eventAction:(args:any)=>void) {
      console.log('Registered event handler for ' + eventType);
      this.list.has(eventType) || this.list.set(eventType, []);
      if (this.list.get(eventType)) this.list.get(eventType).push(eventAction);
      return this;
   },

   emit(eventType:string, ...args:any) {
      console.log('Emitting event for ' + eventType);
      this.list.get(eventType) &&
      this.list.get(eventType).forEach((cb:any) => {
         cb(...args);
      });
   }
};
