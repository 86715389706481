import {atom} from "recoil";
import {Language} from "./showroom/FHApi";

export const AppAtom = atom<AppData | null>({
   key: 'app',
   default: null
})

export interface AppData {
   imageBaseUrl: string,
   currency: AppCurrency,
   language: AppLanguage,
   company: AppCompany,
   carturl: string
}

export type AppLanguage = 'DA' | 'nb-no' | 'sv' | 'en-gb'
export type AppCurrency = 'DKK' | 'NOK' | 'SEK' | 'EUR' | 'GBP';
export type AppCompany = 'FHDK102921' | 'FHNO200663' | 'FHSE300265'