import {Currency, FHApi, Language, Localization} from "./FHApi";

export interface Product {
   selected: Variant
   variants: Variant[],
}

export interface Variant {
   brand: string;
   productName: string;
   itemid: string,
   ean: string,
   image: string,
   images: string[],
   description: string,
   material: string,
   price?: number,
   priceBefore?: number,
   size: string,
   color: string,

   allowedSale?: boolean,
   dateExpected?: Date,
   quantity?: number
}

const findImage = (images: string[], itemId: string, defaultLast: boolean) => {
   let matchImage = images.find(image => {
      return image.endsWith(itemId + ".png") ||
         image.endsWith(itemId + ".jpg")
   }) || null;

   if (defaultLast && !matchImage) {
      matchImage = images[images.length - 1]
   }
   return matchImage;
}

const findOthers = (images: string[], itemId: string, mainImage: string | null) => {
   return images.filter(i => {
      return mainImage && i !== mainImage && i.includes(itemId)
   })
}

const getProduct = async (ean: string, language: Language, company: string, currency: Currency) => {
   const [fhItems, fhStatus, fhOnSale] = await Promise.all([
      FHApi.getFHData(ean, company),
      FHApi.getFHStatus(ean),
      FHApi.getFHOnSale(ean, currency)
   ]);

   if (fhItems.length == 0) {
      return null
   }

   let fhItem = fhItems[0];

   let localization = fhItem.document.localization.find(loc => {
      return loc.language === language
   }) as Localization;

   if (!localization) {
      localization = {
         ProductName: fhItem.document.ProductName,
         ProductType: fhItem.document.ProductType,
         ProductMaterial: fhItem.document.ProductMaterial,
         ProductSalesText: fhItem.document.ProductSalesText,
         ProductWebText: fhItem.document.ProductWebText,
         ProductWarrantTableDescription: fhItem.document.ProductWarrantTableDescription,
         language: language,
         "main-color": fhItem.docs["main-color"],
         color: fhItem.docs.color
      }
   }

   let images = fhItem.document.Images;

   let variants = fhItem.document.variants.map(v => {
      let mainImage = findImage(images, v.itemid, true);
      let otherImages = findOthers(images, v.itemid, mainImage);
      let colors = v.colors.find(c => c.language === language);

      let status = v.eancode===ean?fhStatus:undefined;
      let salesPrice = fhOnSale?.price||undefined;
      let currenPrice = v.prices.find(p => p.Currency === currency)?.price || undefined;

      let actualPrice;
      let beforePrice;
      if(salesPrice && salesPrice < currenPrice!) {
         actualPrice = salesPrice;
         beforePrice = currenPrice;
      } else {
         actualPrice = currenPrice;
         beforePrice = undefined;
      }
      return {
         brand: v.Brand,
         productName: localization.ProductName,
         ean: v.eancode,
         description: localization.ProductWebText,
         material: localization.ProductMaterial,
         itemid: v.itemid,
         image: mainImage,
         images: otherImages,
         price: actualPrice,
         priceBefore: beforePrice, // insert sales price here
         size: v["size-name"] || v.size,
         color: colors?.color || colors?.maincolor,
         ...status
      }
   });

   let selected = variants.find(v => {
      return v.ean === ean
   });

   return {
      variants: variants,
      selected: selected
   } as Product
}

export const ProductBusiness = {
   getProduct
}