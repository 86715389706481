import React, {useEffect, useState} from 'react';
import {createMuiTheme, LinearProgress, MuiThemeProvider, Typography} from "@material-ui/core";
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import {RecoilRoot, useRecoilState} from "recoil";
import {ShowRoom} from './showroom/ShowRoom';
import {PubSub} from "./utility/PubSub";
import {Strings} from "./Localizations";
import {AppAtom} from "./AppAtom";
import {Currency, Language} from "./showroom/FHApi";
import {systemCurrency, systemLanguage} from "./index";

const theme = createMuiTheme({
   overrides: {
      MuiTextField: {
         root: {
            marginTop: '5px',
            marginBottom: '5px',
            marginRight: '5px',
         }
      },
      MuiPaper: {
         root: {
            marginTop: '10px',
            padding: '20px',
         }
      },
      MuiDivider: {
         root: {
            marginTop: '20px',
            marginBottom: '20px',
         }
      },
      MuiButton: {
         root: {
            fontSize: '15px'
         }
      }
   },
   palette: {
      primary: {
         main: '#C5A669'
      },
      secondary: {
         main: '#ffffff'
      },
      background: {
         default: 'transparent'
      }
   },
   typography: {
      fontFamily: '"Assistant", -apple-system,BlinkMacSystemFont,San Francisco,Segoe UI,Roboto,Helvetica Neue,sans-serif',
      button: {
         textTransform: 'none',
         backgroundColor: 'transparent',
         color: '#FFFFFF',
         fontSize: '15px',
         border: '1px solid transparent !important'
      },
      h1: {
         letterSpacing: '0.3px',
         fontSize: '30px',
         fontWeight: 600
      },
      h2: {
         letterSpacing: '0.24px',
         fontSize: '24px',
         fontWeight: 600
      },
      h3: {
         letterSpacing: '0.16px',
         fontSize: '22px',
         fontWeight: 600
      },
      h4: {
         letterSpacing: '0.16px',
         fontSize: '16px',
         fontWeight: 600
      },
      body1: {
         letterSpacing: '0.15px',
         fontSize: '15px',
         fontWeight: 400
      },
      body2: {
         letterSpacing: '0.15px',
         fontSize: '15px',
         fontWeight: 400,
         color: '#404040'
      },
      subtitle1: {
         letterSpacing: '0.15px',
         fontSize: '15px',
         fontWeight: 400,
         lineHeight: 1.3
      }
   }
});

function App() {
   const [ready, setReady] = useState<boolean>(false);
   const [noCamera, setNoCamera] = useState<boolean>(false);

   useEffect(() => {
      Strings.setLanguage(systemLanguage);

      let elementById = document.getElementById('scantitle');
      if(elementById) {
         elementById.innerHTML = Strings.scanTitle;
      }

      PubSub.on('scanready', () => {
         setReady(true);
      });
      PubSub.on('nocamera', () => {
         setNoCamera(true);
      })
   }, [])

   if(noCamera) {
      return <MuiThemeProvider theme={theme}>
         <ScopedCssBaseline>
            <Typography variant={"h1"}>{Strings.noCamera}</Typography>
         </ScopedCssBaseline>
      </MuiThemeProvider>
   }

   if (!ready) {
      return <LinearProgress/>
   }

   return <>
      <MuiThemeProvider theme={theme}>
         <ScopedCssBaseline>
            <RecoilRoot>
               <AppView/>
            </RecoilRoot>
         </ScopedCssBaseline>
      </MuiThemeProvider>
   </>
}


const AppView = () => {
   const [app, setApp] = useRecoilState(AppAtom);

   useEffect(() => {
      setApp({
         imageBaseUrl: 'https://www.kitchenlivingdining.com/dw/image/v2/BGLP_PRD/on/demandware.static/-/Sites-FH_master_catalog/default/dw5652e1f5/images/',
         currency: systemCurrency as Currency,
         language: systemLanguage as Language,
         carturl: getCartUrl(),
         company: getCompany()
      })
   }, []);

   if(!app) {
      return <LinearProgress />
   }

   return <ShowRoom/>
}

function getCompany() {
   if(systemLanguage==="DA") {
      return 'FHDK102921' ;
   } else if(systemLanguage==="sv") {
      return 'FHSE300265';
   } else if(systemLanguage==="nb-no") {
      return 'FHNO200663'
   } else {
      return 'FHDK102921';
   }
}


const getCartUrl = () => {
   if(systemLanguage==="DA") {
      return 'https://www.kitchenlivingdining.com/dk/cart-addtocart.html?utm_source=app&emptyCart=true&productsData=';
   } else if(systemLanguage==="sv") {
      return 'https://www.kitchenlivingdining.com/se/cart-addtocart.html?utm_source=app&emptyCart=true&productsData=';
   } else if(systemLanguage==="nb-no") {
      return "https://www.kitchenlivingdining.com/no/cart-addtocart.html?utm_source=app&emptyCart=true&productsData="
   } else {
      return 'https://www.kitchenlivingdining.com/dk/cart-addtocart.html?utm_source=app&emptyCart=true&productsData=';
   }
}

export default App;
