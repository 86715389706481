import {atom} from "recoil";
import {Variant} from "./ProductBusiness";

export const CartAtom = atom<CartItem[]>({
   key: 'cart',
   default: [] as CartItem[]
})

export interface CartItem {
   item: Variant,
   eancode: string,
   amount: number
}
