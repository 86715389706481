export const parseDate = (date: string):Date => {
   return new Date(Date.parse(date));
}

export const isAllowedSale = (date: Date, now?:Date):boolean => {
   date.setHours(6,0,0);
   if(!now) {
      now = new Date();
   }
   now.setHours(12,0,0);
   return now >= date;
}

