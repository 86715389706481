import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import initializeScanner from "./scanner/Scanner";
import {Currency, Language} from "./showroom/FHApi";

let path = window.location.pathname;
let pathLanguage = '';
let pathCurrency = '';

if(path==="/se/") {
   pathLanguage = 'sv';
   pathCurrency = 'SEK';
} else if(path==="/no/") {
   pathLanguage = 'nb-no';
   pathCurrency = 'NOK';
} else {
   pathLanguage = 'DA';
   pathCurrency = 'DKK';
}

export const systemLanguage = pathLanguage as Language;
export const systemCurrency = pathCurrency as Currency;

ReactDOM.render(
   <App/>,
   document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

try {
   // Start the scanner engine
   initializeScanner();
} catch (e) {
   console.log('No camera!');
}
