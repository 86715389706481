import React, {useEffect, useState} from 'react';
import {useRecoilState} from "recoil";
import {CartAtom, CartItem} from "./CartAtom";
import {
   AppBar, Button,
   Card,
   CardContent,
   createStyles,
   Grid,
   IconButton,
   makeStyles,
   Theme,
   Typography
} from "@material-ui/core";
import {PubSub} from "../utility/PubSub";
import {ProductView} from "./ProductView";
import {AppAtom} from "../AppAtom";
import {Strings} from "../Localizations";
import CloseIcon from '@material-ui/icons/Close';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      appBar: {
         top: 'auto',
         bottom: 0,
      },
      title: {
         marginLeft: theme.spacing(2),
         flex: 1,
      },
      grow: {
         flexGrow: 1,
      },
      product: {
         marginBottom: '15px',
      },
      cardContent: {
         padding: '0',
         paddingBottom: '0 !important'
      },
      productName: {},
      productImageContainer: {
         backgroundColor: '#E0E0E0',
         width: '83px',
         height: '83px',
         marginRight: '10px !important',
      },
      productImage: {
         width: '83px',
         height: '83px'
      },
      productContent: {
         paddingLeft: '10px'
      },
      row: {
         display: 'flex',
         flexWrap: 'wrap',
         marginTop: '15px',
         paddingLeft: '15px',
         paddingRight: '15px',
      },
      cellFirst: {
         flex: '0 0 33.33333%',
         maxWidth: '33.33333%',
         marginRight: '5px'
      },
      cellMiddle: {
         flex: '0 0 33.33333%',
         maxWidth: '33.33333%',
         marginRight: '5px',
      },
      cellRest: {
         flexBasis: 0,
         flexGrow: 1,
         maxWidth: '100%',
      },
      removeButton: {
         top: '-10px',
         right: '-10px'
      },
      boldText: {
         fontWeight: 'bold'
      },
      paddingTop: {
         paddingTop: '10px'
      }
   }),
);

export const ShowRoom = () => {
   const [app, setApp] = useRecoilState(AppAtom);
   const [cart, setCart] = useRecoilState(CartAtom);
   const [scan, setScan] = useState<string | null>(null);
   const classes = useStyles();

   useEffect(() => {
      PubSub.on('scan', () => {
         let resultElement = document.getElementById("result");
         let ean = resultElement?.innerText || null;
         if (!ean || !resultElement) {
            return;
         }
         resultElement!.innerHTML = '';
         setScan(ean);
      })
   }, [])

   const handleClose = () => {
      setScan(null);
   }

   const onRemove = (item:CartItem) => {
      setCart(cart.filter(i=>i!==item));
   }

   const onBuy = () => {
      let baseUrl = app?.carturl;
      let cartJson = cart.map(item => {
         return {
            pid: item.item.itemid,
            qty: item.amount
         }
      });
      window.location.assign(baseUrl + encodeURI(JSON.stringify(cartJson)));
   }

   let total = 0;
   for (const cartItem of cart) {
      total += (cartItem.item.price||0)*cartItem.amount;
   }

   if (scan) {
      return <ProductView ean={scan} onClose={handleClose}/>
   } else {
      return <div>
         <AppBar position={"fixed"} className={classes.appBar}>
            <Button variant={"outlined"} color={"secondary"} onClick={onBuy}>{Strings.buy} ({cart.length})</Button>
         </AppBar>
         {cart && cart.length!=0 && <Typography variant={"h1"} className={classes.paddingTop}>{Strings.cart}</Typography>}
         {cart && cart.map(cart => {
            let imageUrl = (app?.imageBaseUrl || '') + cart.item.image + '?sw=140';
            return <Card variant={"outlined"} className={classes.product} key={'card' + cart.eancode}>
               <CardContent className={classes.cardContent}>
                  <Grid xs={12} container>
                     <Grid item container xs={12}>
                        <Grid item xs={8}>
                           {cart.item.productName}
                        </Grid>
                        <Grid item xs={4} container justify="flex-end" alignItems={"flex-end"}>
                           <IconButton className={classes.removeButton} onClick={() => onRemove(cart)}><CloseIcon/></IconButton>
                        </Grid>
                     </Grid>
                     <Grid item container xs={12}>
                        <Grid item xs={3}>
                           <div className={classes.productImageContainer}>
                              <img width={83} height={83} src={imageUrl} className={classes.productImage} alt={cart.item.productName}/>
                           </div>
                        </Grid>
                        <Grid item container xs={9} className={classes.productContent}>
                           <Grid item xs={12}>
                              {Strings.productColor} {cart.item.color}<br/>
                              {cart.item.size}
                           </Grid>
                           <Grid xs={12} container>
                              <Grid item xs={7}>
                                 {Strings.productCount} {cart.amount} {Strings.productCountSuffix}
                              </Grid>
                              <Grid item xs={5} container justify="flex-end" alignItems={"flex-start"} className={classes.boldText}>
                                 {Math.round(100*(cart.item.price || 0) * cart.amount)/100} {app?.currency}
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </CardContent>
            </Card>
         })}
         {cart && cart.length!=0 &&
            <Card variant={"outlined"}>
               <CardContent className={classes.cardContent}>
                  <Grid xs={12} container>
                     <Grid xs={12} item>
                        <Typography variant={"h1"}>
                           {Strings.cartTitle}
                        </Typography>
                     </Grid>
                     <Grid xs={6} item>
                        <Typography variant={"body1"} className={clsx(classes.boldText, classes.paddingTop)}>
                           {Strings.cartTotal}
                        </Typography>
                     </Grid>
                     <Grid xs={6} item container justify="flex-end" alignItems={"flex-start"} className={classes.boldText}>
                        <Typography variant={"h3"}>
                           {(Math.round(100*total)/100).toFixed(2)} {app?.currency}
                        </Typography>
                     </Grid>
                  </Grid>
               </CardContent>
            </Card>
         }
      </div>
   }
}


