import * as ScanditSDK from "scandit-sdk";
import {Barcode, ScanSettings} from "scandit-sdk";
import {PubSub} from "../utility/PubSub";

function initializeScanner() {
   ScanditSDK.configure("AQlhApGBBdSsIjzCA0PHElk16WDBH5AnqFODW9JOLRxVddlGY3oPcMNDZcD9ZO/3cRiRg6Re11nnV8B2Uz9dOfw12DX5d9E36CBvHthwhDneTBnE7HfiGGtf+xZUfv3Yckxx8uFE+nvqVBsQqHyfTp58uKtdeTc6NlHMabB/MFxyLu0uIzmuYAESYdyNGt1+hDyXT9giGCjpBldAfZz3+9xOCIpQNEvHefxGWbhPMias8eTU3YV60wjHIjqU9loKCz4lFuvb8y2bF6P3lBvx24A7ftz+Ztuvgc90/jUT2EN5ZWdfVplcR7ODa1D7/AowvIELDMRdetsxPAPx+b4YbzzxskFaq8WMCg7hsz1467wCYbvcwisufeaVznOJM4eDqQqeeodne4hHGy1YbOqtY22k46BDAmxXp8WeEF4f16gOFjzN+2hWoIpbkssdAggklExdgVp/0IoDdpAJsa+C0GvuAO+iySm47miqSBdzuuJxEFcyO6XRD8tGpxm9KkVYNQ593t+o888Od7vdEPrmaYsQNjYwzxVCnW83vbhAKYeuj87Or5q2aeTSproyd8FkhiC60guJ7QnW3KS9EYZtAa+xZ1qMhmyZy12a6U7xmBWajzDm89C9InWlOZW5BMtKAWzlw1/RvYmejmrVQHSwwKGSfNQC3J9a17mp1Sg03GWVrim3e+NoJYjUm0PsSEVm/OfASdYYfOGowcp8ANInc8B8mY0rMr1rBvyrpK+B3jAoQ0GwMLONFZymUsEUK+NHeIl3DYeQiNJfaKLSteCK2G1/qy6Po5JkjSkVUIBWcALG3HOrOzkHms+W7HARsqJuOqgBgcXEE4oSLSm4TehsJGVuZ4Tb/H52wOGVDbVSsxK1fTWET3w=", {
      engineLocation: "https://cdn.jsdelivr.net/npm/scandit-sdk@5.6.x",
      preloadBlurryRecognition: true,
      preloadEngine: true
   }).then(() => {
      // ... ready to instantiate a BarcodePicker or Scanner from here
      let scannerElement = document.getElementById("scanner");
      let resultElement = document.getElementById("result");

      ScanditSDK.BarcodePicker.create(scannerElement!, {
         videoFit: ScanditSDK.BarcodePicker.ObjectFit.COVER,
         guiStyle: ScanditSDK.BarcodePicker.GuiStyle.NONE,
         playSoundOnScan: true,
         vibrateOnScan: true,
         hideLogo: true,
         enableCameraSwitcher: false,
         enableTorchToggle: false,
         enablePinchToZoom: false,
         viewfinderArea: {
            x: 0,
            y: 0.1,
            width: 1,
            height: 0.4
         },

      }).then(function (barcodePicker) {
         const scanSettings = new ScanSettings({
            enabledSymbologies: [
               Barcode.Symbology.EAN8,
               Barcode.Symbology.EAN13,
               Barcode.Symbology.UPCE,
               Barcode.Symbology.CODE128,
               Barcode.Symbology.CODE39,
            ],
            searchArea: {
               x: 0,
               y: 0.1,
               width: 1,
               height: 0.4
            },
            codeDuplicateFilter: 2000, // Minimum delay between duplicate results
         });
         const ean13upcaSettings = scanSettings.getSymbologySettings(Barcode.Symbology.EAN13);
         ean13upcaSettings.enableExtensions(ScanditSDK.SymbologySettings.Extension.REMOVE_LEADING_UPCA_ZERO);

         barcodePicker.applyScanSettings(scanSettings);

         barcodePicker.on("scan", (scanResult) => {
            resultElement!.innerHTML = scanResult.barcodes.reduce((string, barcode) => {
               return barcode.data;
            }, "");
            PubSub.emit('scan');
         });
         barcodePicker.on("ready", () => {
            PubSub.emit('scanready');
         })

         PubSub.on('pause', () => {
            if(!barcodePicker.isScanningPaused()) {
               barcodePicker.pauseScanning(false)
            }
         })
         PubSub.on('resume', () => {
            if(barcodePicker.isScanningPaused()) {
               barcodePicker.resumeScanning();
            }
         })

      }).catch(reason => {
         PubSub.emit('nocamera');
      });
   });
}

export default initializeScanner;
