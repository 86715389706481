import LocalizedStrings, {LocalizedStringsMethods} from 'react-localization';
import * as React from "react";

export interface Localizations extends LocalizedStringsMethods {
   scanTitle: string;
   beforePrice: string;
   addToCart: string;
   addToCartDisabled: string;
   selectedColorPrefix: string;
   productColor: string;
   productCount: string;
   productCountSuffix: string;
   productOnSale: string;
   cart: string;
   buy: string;
   productDescription: string;
   productInformation: string;
   unknownBarCode: string;
   unknownBarCodeTryAgain: string;
   noCamera: string;
   cartTitle: string;
   cartTotal: string;
}

export const Strings:Localizations = new LocalizedStrings({
   da: {
      scanTitle: 'SCAN STREGKODE',
      beforePrice: 'FØR: ',
      addToCart: 'Læg i indkøbskurv',
      addToCartDisabled: 'Ikke på lager, forventet levering ',
      selectedColorPrefix: 'Valgt farve: ',
      currency: 'DKK',
      productColor: 'Farve: ',
      productCount: 'Antal: ',
      productCountSuffix: 'stk.',
      productOnSale: 'Tilbud',
      cart: 'Kurv',
      buy: 'Gå til kurven',
      productDescription: 'Beskrivelse',
      productInformation: 'Produktinformation',
      unknownBarCode: 'Stregkoden findes ikke',
      unknownBarCodeTryAgain: 'Prøv igen',
      noCamera: 'Kræver adgang til et kamera',
      cartTitle: 'Din ordre',
      cartTotal: 'Total',
   },
   'nb-no': {
      scanTitle: 'SKAN STREKKODE',
      beforePrice: 'FØR: ',
      addToCart: 'Legg i handlekurv',
      addToCartDisabled: 'Utsolgt, forventet på lager ',
      selectedColorPrefix: 'Valgt farge: ',
      productColor: 'Farge: ',
      productCount: 'Antall: ',
      productCountSuffix: 'stk.',
      productOnSale: 'Tilbud',
      cart: 'Kurv',
      buy: 'Gå til handlekurven',
      productDescription: 'Beskrivelse',
      productInformation: 'Produktinformasjon',
      unknownBarCode: 'Strekkoden finnes ikke',
      unknownBarCodeTryAgain: 'Prøv igjen',
      noCamera: 'Krever tilgang til et kamera',
      cartTitle: 'Din bestilling',
      cartTotal: 'Totalt',
   },
   sv: {
      scanTitle: 'SKANNA STRECKKOD',
      beforePrice: 'FÖRR: ',
      addToCart: 'Lägg till i varukorg',
      addToCartDisabled: 'Utsåld, beräknas finnas i lager ',
      selectedColorPrefix: 'Vald färg: ',
      productColor: 'Färg: ',
      productCount: 'Antal: ',
      productCountSuffix: 'st',
      productOnSale: 'Rea',
      cart: 'Varukorg',
      buy: 'Gå till varukorg',
      productDescription: 'Beskrivning',
      productInformation: 'Produktinformation',
      unknownBarCode: 'Streckkoden finns inte',
      unknownBarCodeTryAgain: 'Försök igen',
      noCamera: 'Kräver åtkomst till en kamera',
      cartTitle: 'Din beställning',
      cartTotal: 'Total',
   },
   'en-gb': {
      scanTitle: 'SCAN BARCODE',
      beforePrice: 'BEFORE: ',
      addToCart: 'Add to cart',
      addToCartDisabled: 'Not available for sale',
      selectedColorPrefix: 'Selected color: ',
      productColor: 'Color: ',
      productCount: 'Number: ',
      productCountSuffix: 'pcs',
      productOnSale: 'On sale',
      cart: 'Cart',
      buy: 'Check out',
      productDescription: 'Description',
      productInformation: 'Information',
      unknownBarCode: 'Unable to scan barcode',
      unknownBarCodeTryAgain: 'Try again',
      noCamera: 'Requires access to camera',
      cartTitle: 'Your ordre',
      cartTotal: 'Total',
   }
});