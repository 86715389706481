import axios from "axios";
import {isAllowedSale, parseDate} from "./FHDateUtility";

const getFHData = async (ean: string, company: string): Promise<FHItem[]> => {
   return await axios.request<any[]>({
      url: 'https://prod-35.northeurope.logic.azure.com/workflows/74f0e3336e4e41a5a3d699d48861d381/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=X4bEEEN84mX03sjoghDif86rL9SBbknh9RlcnsCinf8&ean=' + ean + '&company=' + company
   }).then((response) => {
      const {data} = response
      return data;
   }).catch(reason => {
      console.log(reason);
      return []
   })
}

const getFHStatus = async (ean: string): Promise<FHStatus|null> => {
   return await axios.request<FHStatus|null>({
      url: 'https://prod-47.northeurope.logic.azure.com/workflows/26bf272f8f7d4089880c0e229a80ae70/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=HEcHgnWZb5Mzlk4XbmB6xzfXhO8xuw7lsH4ivVosBxs&ean=' + ean
   }).then((response) => {
      const {data} = response
      if(Array.isArray(data)) {
         let quantity = data[0].quantity;
         let dateExpectedStr = data[0].dateexpected;

         let parsedDate = parseDate(dateExpectedStr);
         let allowedSale = isAllowedSale(parsedDate);

         return {
            dateExpected: parsedDate,
            quantity: quantity,
            allowedSale: allowedSale
         }
      }  else {
         return null;
      }
   }).catch(reason => {
      console.log(reason);
      return null
   })
}

const getFHOnSale = async (ean: string, currency: string): Promise<FHOnSale|null> => {
   return await axios.request<FHOnSale|null>({
      url: 'https://prod-05.northeurope.logic.azure.com/workflows/269133f55cee4f219d2ab2604945bbef/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=_8D25DQHidOFMgUdck_9A5wkZBksCa6aDkMulr2lPOY&ean=' + ean + '&pricebook=' + currency.toLowerCase() + '_sale'
   }).then((response) => {
      const {data} = response
      if(Array.isArray(data)) {
         let onSale = data[0];
         if(onSale) {
            let fhOnSale = {
               price: parseFloat(onSale.price),
               from: dateParser(onSale.from),
               to: dateParser(onSale.to)
            };

            let now = new Date();
            let validSale: boolean;
            if(fhOnSale?.from && fhOnSale?.to) {
               validSale = (now.getTime() > fhOnSale.from.getTime()) && (now.getTime() <= fhOnSale.to.getTime())
            } else if(fhOnSale?.from) {
               validSale = now.getTime() > fhOnSale.from.getTime()
            } else if(fhOnSale?.to) {
               validSale = now.getTime() <= fhOnSale.to.getTime()
            } else {
               validSale = false;
            }

            if(validSale) {
               return fhOnSale
            } else {
               return null;
            }
         } else {
            return null;
         }
      }  else {
         return null;
      }
   }).catch(reason => {
      console.log(reason);
      return null
   })
}

const dateParser = (dateString: string): Date|null => {
   let dateParts = dateString.split('-');
   if(dateParts.length!=3) {
      return null;
   }
   const months = ['Jan','Feb','Mar','Apr','May', 'Jun', 'Jul', 'Aug', 'Sep', 'Nov','Dec'];
   let year = parseInt(dateParts[2]);
   let month = months.indexOf(dateParts[1])
   let day = parseInt(dateParts[0]);
   return new Date(year, month, day, 12, 0, 0);
}


export const FHApi = {
   getFHData,
   getFHStatus,
   getFHOnSale
}

export type Language = 'DA'|'nb-no'|'sv'|'en-gb'
export type Currency = 'DKK' | 'NOK' | 'SEK' | 'EUR' | 'GBP';

export interface ProductMaterial {
   language: Language;
   materiale: string;
}

export interface Price {
   price: number;
   Currency: Currency;
}

export interface Color {
   language: Language;
   maincolor: string;
   color: string;
}

export interface ProductWarrantTableDescription {
   language: Language;
   description: string;
}

export interface CollectionId {
   language: Language;
   id: string;
}

export interface Variant {
   eancode: string;
   volume: string;
   volumeunit: string;
   width: string;
   size: string;
   color: string;
   "main-color": string;
   "variant-group-id": string;
   "size-name": string;
   itemid: string;
   "in-meters": number;
   "item-status": string;
   "collection-id": string;
   filtercode1: string;
   "product-gross-weight": number;
   "product-length": string;
   "product-height": string;
   ProductMaterial: ProductMaterial[];
   Brand: string;
   "symbol-id": string[];
   prices: Price[];
   colors: Color[];
   ProductWarrantTableDescription: ProductWarrantTableDescription[];
   CollectionIds: CollectionId[];
}

export interface Localization {
   language: Language;
   ProductName: string;
   ProductWebText: string;
   ProductSalesText: string;
   ProductMaterial: string;
   ProductWarrantTableDescription: string;
   ProductType: string;
   color: string;
   "main-color": string;
}

export interface Document {
   "Master-Item-Id": string;
   productid: string;
   "Master-Item-Name": string;
   Images: string[];
   ProductMaterial: string;
   Brand: string;
   ProductDesigner: string;
   ProductType: string;
   ProductName: string;
   ProductWebText: string;
   ProductSalesText: string;
   InventRoyaltyDesigner: string;
   ProductEffectTextAndUnit: string;
   ProductWarrantTableDescription: string;
   ProductTextProductSize: string;
   variants: Variant[];
   localization: Localization[];
}

export interface ProductMaterial2 {
   language: Language;
   materiale: string;
}

export interface Price2 {
   price: number;
   Currency: Currency;
}

export interface Color2 {
   language: Language;
   maincolor: string;
   color: string;
}

export interface ProductWarrantTableDescription2 {
   language: Language;
   description: string;
}

export interface CollectionId2 {
   language: Language;
   id: string;
}

export interface Docs {
   eancode: string;
   volume: string;
   volumeunit: string;
   width: string;
   size: string;
   color: string;
   "main-color": string;
   "variant-group-id": string;
   "size-name": string;
   itemid: string;
   "in-meters": number;
   "item-status": string;
   "collection-id": string;
   filtercode1: string;
   "product-gross-weight": number;
   "product-length": string;
   "product-height": string;
   ProductMaterial: ProductMaterial2[];
   Brand: string;
   "symbol-id": string[];
   prices: Price2[];
   colors: Color2[];
   ProductWarrantTableDescription: ProductWarrantTableDescription2[];
   CollectionIds: CollectionId2[];
}

export interface FHItem {
   document: Document;
   docs: Docs;
}

export interface FHStatus {
   dateExpected: Date,
   quantity: number,
   allowedSale: boolean
}

export interface FHOnSale {
   price: number
   from: Date|null
   to: Date|null
}