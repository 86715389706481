import {FHApi, FHItem} from "./FHApi";
import {
   Backdrop,
   Button, CircularProgress,
   createStyles,
   Dialog,
   DialogContent, FormControl,
   IconButton, InputLabel,
   LinearProgress,
   makeStyles, MenuItem, Select,
   Slide,
   Theme, Typography, withStyles
} from "@material-ui/core";

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import {ImageViewer} from "./ImageViewer";
import React, {useEffect, useState} from "react";
import {TransitionProps} from "@material-ui/core/transitions";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {Strings} from "../Localizations";
import {useRecoilState} from "recoil";
import {AppAtom} from "../AppAtom";
import clsx from "clsx";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {CartAtom} from "./CartAtom";
import {ProductBusiness, Variant} from "./ProductBusiness";
import {groupBy} from "../utility/GroupBy";
import {PubSub} from "../utility/PubSub";

export type ProductViewProps = {
   ean: string,
   onClose: () => void
}

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & { children?: React.ReactElement },
   ref: React.Ref<unknown>,
) {
   return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      backdrop: {
         zIndex: theme.zIndex.drawer + 1,
         color: '#fff',
      },
      content: {
         backgroundColor: '#EDEDED',
         flex: 'none'
      },
      contentNoScan: {
         padding: '30px',
         backgroundColor: '#EDEDED',
         flex: 'none'
      },
      contentNoScanButton: {
         width: '100%',
         backgroundColor: '#C5A669',
         marginTop: '20px'
      },
      contentNoMargin: {
         paddingTop: 10,
         paddingLeft: 0,
         paddingRight: 0,
         paddingBottom: 0
      },
      background: {
         backgroundColor: '#ffffff'
      },
      productImage: {
         position: 'relative',
         height: '230px',
         textAlign: 'center',
      },
      image: {
         display: 'inline',
         height: '230px'
      },
      productBrand: {
         marginTop: '20px',
         textAlign: 'center',
         fontSize: '12px'
      },
      productTitle: {
         textAlign: 'center',
         fontSize: '22px'
      },
      productPriceBefore: {
         marginTop: '20px',
         color: '#333333',
         fontSize: '16px',
         textAlign: 'center',
         opacity: '0.46'
      },
      productPrice: {
         fontSize: '26px',
         textAlign: 'center',
      },
      productCount: {
         backgroundColor: '#EDEDED',
      },
      productSize: {},
      productAddCart: {
         backgroundColor: '#C5A669',
      },
      productAddDisabled: {
         backgroundColor: '#E0E0E0',
         color: '#000000'
      },
      productOnSale: {
         position: 'absolute',
         right: 5,
         backgroundColor: '#ba6c68',
         color: 'white',
         padding: '10px',
         top: '.8125rem',
         paddingTop: '.625rem',
         paddingBottom: '.625rem',
         paddingLeft: '.9375rem',
         paddingRight: '.9375rem',
         lineHeight: 1,
         textTransform: 'uppercase',
      },
      variant: {
         cursor: 'pointer',
         display: 'inline',
         maxWidth: '50px',
         maxHeight: '50px',
         flex: '1',
         marginRight: '5px',
         border: '1px solid transparent'
      },
      variantSelected: {
         border: '1px solid #000000'
      },
      row: {
         display: 'flex',
         flexWrap: 'wrap',
         marginTop: '15px',
         paddingLeft: '15px',
         paddingRight: '15px'
      },
      cellFirst: {
         flex: '0 0 33.33333%',
         maxWidth: '33.33333%',
         marginRight: '5px'
      },
      cellRest: {
         flexBasis: 0,
         flexGrow: 1,
         maxWidth: '100%'
      },
      formControl: {},
      accordion: {
         paddingLeft: '15px',
         paddingRight: '15px',
         paddingTop: '0',
         paddingBottom: '0'
      },
      accordionSummary: {
         paddingLeft: '5px',
         paddingRight: '5px'
      },
      accordionDetails: {
         paddingLeft: '5px',
         paddingRight: '5px',
      },
      appBar: {
         top: 'auto',
         bottom: 0,
      },
      title: {
         marginLeft: theme.spacing(2),
         flex: 1,
      },
      grow: {
         flexGrow: 1,
      },
      centered: {
         alignItems: 'center',
         justifyContent: 'center'
      }
   }),
);

export const ProductView = (props: ProductViewProps) => {
   const [app, setApp] = useRecoilState(AppAtom);
   const [cart, setCart] = useRecoilState(CartAtom);

   const [loading, setLoading] = useState<string>('');
   const [variants, setVariants] = useState<Variant[]>([]);
   const [selected, setSelected] = useState<Variant | null>(null);
   const [sizeMap, setSizeMap] = useState<any>([]);
   const [colorMap, setColorMap] = useState<any>([]);
   const [sizes, setSizes] = useState<string[]>([]);
   const [colors, setColors] = useState<string[]>([]);
   const [count, setCount] = useState<number>(1);
   const [unknown, setUnknown] = useState<boolean>(false);

   const classes = useStyles();

   useEffect(() => {
      PubSub.emit('pause');
   }, [])

   useEffect(() => {
      (async () => {
         if (!props.ean) {
            return;
         }
         let product = await ProductBusiness.getProduct(props.ean, app?.language || 'DA', app?.company || 'FHDK102921', app?.currency || 'DKK');
         if (!product) {
            setUnknown(true);
         } else {
            setVariants(product.variants);
            setSelected(product.selected);
         }
      })()
   }, [props.ean])

   useEffect(() => {
      let sizeMap = groupBy(variants, (v) => v.size);
      setSizeMap(sizeMap);
      let colorMap = groupBy(variants, (v) => v.color);
      setColorMap(colorMap);

      let colors = Object.keys(colorMap);
      colors.sort();
      let sizes = Object.keys(sizeMap);
      sizes.sort();

      setColors(colors);
      setSizes(sizes);
   }, [selected])

   const onAmountChange = (e: any) => {
      setCount(Number(e.target.value));
   }
   const onColorChange = async (e: any) => {
      let ean = e.target.value;
      let eanMatch = variants.find(v => v.ean === ean) || null;
      let expectedColor = eanMatch?.color;

      let bestMatch = variants.find(v => v.color === expectedColor && selected?.size === v.size);
      if (bestMatch) {
         await updateSelected(bestMatch);
      } else {
         let sizeMatch = variants.find(v => v.color === expectedColor) || null;
         await updateSelected(sizeMatch);
      }
   }
   const onSizeChange = async (e: any) => {
      let expectedSize = e.target.value;
      let bestMatch = variants.find(v => v.size === expectedSize && selected?.color === v.color);
      if (bestMatch) {
         await updateSelected(bestMatch);
      } else {
         let sizeMatch = variants.find(v => v.size === expectedSize) || null;
         await updateSelected(sizeMatch);
      }
   }

   const updateSelected = async (selected: Variant | null) => {
      setLoading(selected?.ean || '');
      if (selected && selected.allowedSale === undefined) {
         let newVar = await FHApi.getFHStatus(selected.ean);
         selected.allowedSale = newVar?.allowedSale || false;
         selected.dateExpected = newVar?.dateExpected || undefined;
         selected.quantity = newVar?.quantity;

         let onSale = await FHApi.getFHOnSale(selected.ean, app?.currency || 'DKK');
         selected.priceBefore = onSale?.price || undefined
      }
      setSelected(selected);
      setLoading('');
   }

   const onAddToCart = () => {
      let existing = cart.find(c => c.eancode === selected?.ean)
      let filtered = cart.filter(c => c.eancode !== selected?.ean)

      let amount = count;
      if (existing?.amount) {
         amount = existing.amount;
      }
      setCart([
         {
            amount: amount,
            eancode: selected!.ean,
            item: selected!
         },
         ...filtered
      ])
      onClose();
   }

   const onClose = () => {
      PubSub.emit('resume');
      props.onClose();
   }

   return <Dialog fullScreen open={true} onClose={onClose} TransitionComponent={Transition}
                  PaperProps={{style: {padding: 0}}}>
      {loading !== '' &&
      <Backdrop open={true} className={classes.backdrop}>
         <CircularProgress size={80}/>
      </Backdrop>
      }
      <div><IconButton onClick={onClose}><ArrowBackIcon/></IconButton></div>
      {(!unknown && !selected) && <LinearProgress/>}
      {unknown && <div>
         <div className={classes.contentNoScan}>
            <Typography variant={"h1"}>
               {Strings.unknownBarCode}
            </Typography>
            <Button onClick={onClose} className={classes.contentNoScanButton} variant={"outlined"}
                    color={"secondary"}>
               {Strings.unknownBarCodeTryAgain}
            </Button>
         </div>
      </div>}
      {selected &&
      <div className={classes.content}>
         <div className={classes.productImage}>
            {selected.priceBefore &&
            <div className={classes.productOnSale}><Typography>{Strings.productOnSale}</Typography></div>}
            <img width={230} height={230} src={app?.imageBaseUrl + selected.image + '?sw=800'} alt={selected.image}
                 className={classes.image}/>
         </div>
         <div className={clsx(classes.background, classes.contentNoMargin)}>
            <Typography className={classes.productBrand}>{selected.brand}</Typography>
            <Typography className={classes.productTitle}>{selected.productName}</Typography>
            {selected.priceBefore &&
            <Typography variant={"body1"}
                        className={classes.productPriceBefore}>{Strings.beforePrice} {selected.priceBefore.toLocaleString("da-DK")} {app?.currency}
            </Typography>
            }
            <Typography className={classes.productPrice}
                        variant={"body1"}>{selected.price?.toLocaleString("da-DK")} {app?.currency}</Typography>

            <div className={clsx(classes.row, classes.centered)}>
               {colors.map(k => {
                  let v = colorMap[k][0];
                  let isSelected = v.color === selected.color;
                  return <>
                     <img width={140} height={140} key={'img_' + v.ean} onClick={() => onColorChange({
                        target: {value: v.ean}
                     })} className={clsx(classes.variant, isSelected && classes.variantSelected)}
                          src={app?.imageBaseUrl + v.image + '?sw=140'} title={k} alt={k}/>
                  </>
               })}
            </div>
            <div className={clsx(classes.row, classes.centered)}>
               <Typography variant={"body1"}>{Strings.selectedColorPrefix} {selected.color}</Typography>
            </div>
            <div className={classes.row}>
               <Select
                  native
                  variant={"outlined"}
                  labelId="productSizes"
                  id="productSizes-outlined"
                  value={selected.size || null}
                  onChange={onSizeChange}
                  className={clsx(classes.productSize, classes.cellRest)}
                  inputProps={{
                     name: 'Size',
                     id: 'id-size'
                  }}
               >
                  {sizes.map(size => {
                     let exists = colorMap[selected.color].find((v: any) => v.size === size);
                     if (exists) {
                        return <option key={'size' + size} value={size}>{size || 'One-Size'}</option>
                     } else {
                        return null;
                     }
                  })}
               </Select>
            </div>
            <div className={classes.row}>
               <Select
                  native
                  variant={"outlined"}
                  labelId="productCount"
                  id="productCount-outlined"
                  value={count}
                  onChange={onAmountChange}
                  className={clsx(classes.productCount, classes.cellFirst)}
               >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
               </Select>
               <Button disabled={!selected.allowedSale} onClick={onAddToCart}
                       className={clsx(selected.allowedSale && classes.productAddCart, !selected.allowedSale && classes.productAddDisabled, classes.cellRest)}
                       variant={"text"}>
                  {!selected.allowedSale && Strings.addToCartDisabled + ' ' + selected.dateExpected?.toLocaleDateString(app?.language, {
                     day: 'numeric',
                     month: 'long'
                  })}
                  {selected.allowedSale && Strings.addToCart}
               </Button>
            </div>
            <Accordion className={classes.accordion}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
               >
                  <Typography>{Strings.productDescription}</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography>
                     {selected.description}
                  </Typography>
               </AccordionDetails>
            </Accordion>
            <Accordion className={classes.accordion}>
               <AccordionSummary
                  expandIcon={<ExpandMoreIcon/>}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
               >
                  <Typography>{Strings.productInformation}</Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Typography>
                     {selected.material}
                  </Typography>
               </AccordionDetails>
            </Accordion>
         </div>
      </div>
      }
   </Dialog>
}


const Accordion = withStyles({
   root: {
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      marginTop: '10px !important',
      boxShadow: 'none',
      '&:not(:last-child)': {},
      '&:before': {
         display: 'none',
      },
      '&$expanded': {
         margin: 'auto',
      },
   },
   expanded: {
      marginTop: '10px !important',
   },
})(MuiAccordion);

const AccordionSummary = withStyles({
   root: {
      backgroundColor: 'rgba(0, 0, 0, .03)',
      marginBottom: -1,
      minHeight: 56,
      '&$expanded': {
         minHeight: 56,
      },
      paddingLeft: '5px',
      paddingRight: '5px',
   },
   content: {
      '&$expanded': {
         margin: '12px 0',
      },
   },
   expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
   root: {
      paddingLeft: '5px',
      paddingRight: '5px',
   },
}))(MuiAccordionDetails);
